import axios from "axios";
import jwt from "jsonwebtoken";
import history from "./../history";
import {
  EMPTY_SUCCESS_MESSAGE,
  EMPTY_ERROR_MASSAGE,
  ERROR_MASSAGE,
  SUCCESS_MESSAGE
} from "./types";
import setAuthorixationToken from "../utils/setAuthorizationToken";
import appConfig from "../helpers/appConfig";
import { socket } from "../utils/socketNew";
import { isServerLogout } from "../utils/isServerLogout";
import { getLoginInfo } from "./loginInfo";
import { getEmpPermissions } from "./ansatteAction";
export const postLogin = (userData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/auth/login`,
      userData
    );
    if(res.data.status==400){
      // dispatch({ type: ERROR_MASSAGE, payload:res.data.message});
      throw new Error(res.data.message)
    }
    if (res.data.status == 200) {
      // dispatch({ type: ERROR_MASSAGE, payload:res.data.message});
     
    
      dispatch(getLoginInfo(res.data.data.info))
      dispatch(getEmpPermissions(res.data.data.info))
    }
    const token = await res.data.data.token;
    localStorage.setItem("token", token);
    setAuthorixationToken(token);
    let {
      firstName,
      lastName,
      email,
      phoneNo,
      taccountant,
      managecat,
      crm,
      hrm,
      stock,
      invoice,
      openaccount,
      tableview,
      kitchen,
      bar,
      kitchenpermission,
      barpermission,
      tippermission,
      couponPermission,
      vippsPermission,
      tdDiscountPermission,
      isTreeDriveAllowed,
      tdDiscount,
      DriveMyselfPermission,
      isFindDriver,
      openaccountWeb,
      isWebOpperKonto,
    //isVendorAllowed,
      isTreeDriveDriveOutAllowed,
      isTreeDrivePickUpAllowed,
      branchAdmin,
      branchId
    } = res.data.data.info;

    localStorage.setItem(
      "user",
      JSON.stringify({
        firstName,
        lastName,
        email,
        phoneNo
      })
    );
    let { slug, address, logo, taxes, name, accountNumber, taxNo, isQrAllow } =
      res.data.data.info.branchId;
    const btaxes = JSON.stringify(taxes) || [];
    const phone = res.data.data.info.branchId.phoneNo
    const bEmail = res.data.data.info.branchId.email;
    localStorage.setItem("eid", res.data.data.info._id);
    localStorage.setItem("bid", res.data.data.info.branchId._id);
    localStorage.setItem("isAutoPayAllowed", res.data.data.info.branchId.isAutoPayAllowed ? res.data.data.info.branchId.isAutoPayAllowed : false);
    console.log("-------branchId.branchType--",branchId.branchType)
    localStorage.setItem("tflamount",res.data.data.info.branchId.tfl.amount)
    localStorage.setItem("isVendorAllowed",res.data.data.info.branchId.isVendorAllowed)
    localStorage.setItem("slug", slug);
    localStorage.setItem(
      "branchInfo",
      JSON.stringify(
        {
        name,
        accountNumber,
        bEmail,
        taxNo,
        address,
          isQrAllow,
          phone
      })
    );
    localStorage.setItem(
      "driveoutCharge",
      res.data.data.info.branchId.driveoutCharge
    );
    localStorage.setItem("logo", logo);
    localStorage.setItem("profileImage", res.data.data.info.profileImage);
    localStorage.setItem("empType", res.data.data.info.empType);
    localStorage.setItem("taxes", btaxes);
    localStorage.setItem("currentOrders", res.data.data.info.currentOrders);
    localStorage.setItem(
      "tflamount",
      res.data.data.info.branchId && res.data.data.info.branchId.tfl
        ? res.data.data.info.branchId.tfl.amount
        : 0
    );
    localStorage.setItem("taccountant", taccountant);
    localStorage.setItem("managecat", managecat);
    localStorage.setItem("crm", crm);
    localStorage.setItem("hrm", hrm);
    localStorage.setItem("stock", stock);
    localStorage.setItem("invoice", invoice);
    localStorage.setItem("openaccount", openaccount);
    localStorage.setItem("tableview", tableview);
    localStorage.setItem("kitchen", kitchen);
    localStorage.setItem("bar", bar);
    localStorage.setItem("kitchenpermission", kitchenpermission);
    localStorage.setItem("barpermission", barpermission);
    localStorage.setItem("tippermission", tippermission);
    localStorage.setItem("couponPermission",couponPermission);
    localStorage.setItem("vippsPermission",vippsPermission);
    
    localStorage.setItem("tdDiscountPermission", tdDiscountPermission);
    localStorage.setItem("tdDiscount", tdDiscount);
    localStorage.setItem("isTreeDriveAllowed",isTreeDriveAllowed);
    localStorage.setItem("DriveMyselfPermission", DriveMyselfPermission);
    localStorage.setItem("isFindDriver", isFindDriver);
    localStorage.setItem("openaccountWeb", openaccountWeb);
    localStorage.setItem("isWebOpperKonto", isWebOpperKonto);
    //localStorage.setItem("isVendorAllowed", isVendorAllowed);
    localStorage.setItem("isRestaurantAvailable", res.data.data.info.branchId.isRestaurantAvailable);
    localStorage.setItem("isTreeDrivePickUpAllowed",isTreeDrivePickUpAllowed);
    localStorage.setItem("isTreeDriveDriveOutAllowed", isTreeDriveDriveOutAllowed);
    localStorage.setItem("sadm", branchAdmin);
    localStorage.setItem("branchType",branchId.branchType);
    console.log("-------branchId.branchType--",branchId.branchType)
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: res.data
    });
    history.push("/dashboard");
  } catch (error) {
    if (error) {
      dispatch({ type: ERROR_MASSAGE, payload: error });
    }
  }
};
export const logout = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/auth/logout`
    );
    localStorage.removeItem("token");
    localStorage.removeItem('socketOrders');
    setAuthorixationToken(false);
    socket.emit("logout")
    history.push("/");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const forgotPassword = (email) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/auth/forget-password`,
      email
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "forgotPassword" }
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const resetPassword = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/employee/auth/reset-password`,
      data
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { ...res.data, actionType: "resetPassword" }
    });
  } catch (error) {
    if (error.response) {

      if (error.response.status === 401) {
        isServerLogout(error.response)
      } else {
        dispatch({ type: ERROR_MASSAGE, payload: error.response });
      }
    }
  }
};
export const emptySuccessMsg = () => async (dispatch) => {
  dispatch({ type: EMPTY_SUCCESS_MESSAGE, payload: "" });
};
export const emptyErrorMsg = () => async (dispatch) => {
  dispatch({ type: EMPTY_ERROR_MASSAGE, payload: "" });
};
