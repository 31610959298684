import React, { Component, Fragment } from "react";
import { Icon as RIcon } from "react-icons-kit";
import {chevronDown} from 'react-icons-kit/fa/chevronDown'
import {
  Drawer,
  Menu,
  Dropdown,
  Divider,
  Col,
  Row,
  notification
} from "antd";
import "./Profile.css";

import appConfig from "../../helpers/appConfig";

const pStyle = {
  fontSize: 16,
  color: "rgba(0,0,0,0.85)",
  lineHeight: "24px",
  display: "block",
  marginBottom: 16
};

const DescriptionItem = ({ title, content }) => (
  <div
    style={{
      fontSize: 14,
      lineHeight: "22px",
      marginBottom: 7,
      color: "rgba(0,0,0,0.65)"
    }}
  >
    <p
      style={{
        marginRight: 8,
        display: "inline-block",
        color: "rgba(0,0,0,0.85)"
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true
    };
  }

  switchBranch = (id) => {
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/branch/${id}`, {
      method: "POST",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            // alert(result.data.branchId);
            console.log("ReST DATA __T_T_T_T", result.data);
            localStorage.setItem("crm", result.data.crm);
            localStorage.setItem("hrm", result.data.hrm);
            localStorage.setItem("taccountant", result.data.taccountant);
            localStorage.setItem("stock", result.data.stock);
            localStorage.setItem("invoice", result.data.invoice);
            localStorage.setItem("managecat", result.data.managecat);
            localStorage.setItem("openaccount", result.data.openaccount);
            localStorage.setItem("tableview", result.data.tableview);
            localStorage.setItem("kitchen", result.data.kitchen);
            localStorage.setItem("bar", result.data.bar);
            localStorage.setItem("tdDiscountPermission", result.data.tdDiscountPermission);
            localStorage.setItem("logo", result.data.logo);
            localStorage.setItem("currentOrders", result.data.currentOrders);
            localStorage.setItem("bid", result.data.branchData._id);
            localStorage.setItem("isAutoPayAllowed", result.data.branchData.isAutoPayAllowed ? result.data.branchData.isAutoPayAllowed : false);
            localStorage.setItem("slug", result.data.branchData.slug);

            // localStorage.setItem()

            this.setState({ isLoaded: true });
            // window.location.reload();
            setTimeout(() => {
              window.location.href = "/dashboard";
            }, 500); // Delay of 500ms
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  handleBranchChange() {}

  render() {
    const {
      firstName,
      lastName,
      email,
      phoneNo,
      managecat,
      crm,
      hrm,
      invoice,
      stock,
      taccountant,
      branchList,
      branchId,
      personalInfo
    } = this.props.userData;
    const regex = /(^(.)|png|jpeg|jpg)$/;
    let myRoles = [];
    managecat === 1 && myRoles.push("Manage");
    crm === 1 && myRoles.push("CRM");
    hrm === 1 && myRoles.push("HRM");
    invoice === 1 && myRoles.push("Invoice");
    stock === 1 && myRoles.push("Stock");
    taccountant === 1 && myRoles.push("T Accountant");
    return (
      <Drawer
        width={640}
        placement="right"
        closable={true}
        onClose={this.props.close}
        visible={this.props.visible}
      >
        <p style={{ ...pStyle, marginBottom: 24 }}>User Profile</p>
        <p style={pStyle}>Personal Details</p>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Full Name"
              content={
                <span class="profile-full-name">
                  {firstName} {lastName}
                </span>
              }
            />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Email" content={email} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Phone Number" content={phoneNo} />
          </Col>
          <Col span={12}>
            <Dropdown
              className="profile"
              trigger={["click"]}
              overlay={
                branchList && branchList.length > 1 ? (
                  <Menu>
                    {branchList.map((branch, index) => {
                      var tt = {};
                      if (branch.branch) {
                        tt._id = branch.branch._id;
                        tt.name = branch.branch.name;
                      } else {
                        tt._id = branch._id;
                        tt.name = branch.name;
                      }
                      return (
                        <Menu.Item key={index}>
                          <a onClick={() => {
                            console.log("im clicked")
                            this.switchBranch(tt._id)}
                                }>
                            {tt.name}
                          </a>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                ) : (
                  <Menu>
                    {branchList &&
                      branchList.map((branch, index) => {
                        let { _id, name } = branch;
                        return (
                          <Menu.Item key={index}>
                            <a onClick={() => {
                              this.switchBranch(_id)
                              console.log("clickedddd")}}>{name}</a>
                          </Menu.Item>
                        );
                      })}
                  </Menu>
                )
              }
            >
              <a className="ant-dropdown-link change-branch">
                {branchId ? branchId.name : ""}
                <RIcon Icon={chevronDown} />
              </a>
            </Dropdown>
          </Col>
          <Col span={24}>
            <DescriptionItem
              title="Roles"
              content={myRoles.map((item, index) => {
                if (index > 0)
                  return <span className="profile-role">{item}</span>;
                else return <span className="profile-role">{item}</span>;
              })}
            />
          </Col>
        </Row>
        <Divider />
        <p style={pStyle}>Documents</p>
        <Row className="documents">
          <Col span={24}>
            {personalInfo && personalInfo.length > 0
              ? personalInfo.map((item, index) => {
                  return (
                    <div>
                      Document {index + 1}:
                      <img
                        style={{ maxWidth: "100%" }}
                        src={`${appConfig.s3url}/${item}`}
                        key={item}
                      />
                    </div>
                  );
                })
              : ""}
          </Col>
        </Row>
      </Drawer>
    );
  }
}

export default Profile;
