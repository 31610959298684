import React, { Component, Fragment } from "react";
import "./LandingPage.css";
import { connect } from "react-redux";
import companyLogo from "../../assets/Aryan-Holding-Group-Logo-2.png";
import backgroundPhone from "../../assets/1585.jpg";
import background from "../../assets/1586.jpg";
import companyLogo1 from "../../assets/Aryan-Holding-Group-Logo-Square.png";
import { push } from "react-router-redux";
import { notification, Modal, Button, Form, Input, Spin } from "antd";
import setAuthorizationToken from "../../utils/setAuthorizationToken";
import appConfig from "../../helpers/appConfig";

const FormItem = Form.Item;

class LandingPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoaded: false,
      isAuthenticated: false,
      first_name: "",
      last_name: "",
      password: "",
      email: "",
      phone_no: "",
      visible: false,
      apiHit: false,
      currentScreen: "email",
      loginLoading: false
    };
  }

  handleSubmitLogin = (e) => {
    this.setState({ loginLoading: true });
    e.preventDefault();
    const { email, password } = this.state;
    this.setState({ apiHit: true });
    let data = JSON.stringify({
      email: email,
      password: password
    });
    fetch(`${appConfig.endpoint}/login`, {
      method: "POST",
      body: data,
      headers: { "Content-Type": "application/json" }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ apiHit: false });
          if (result.status === "failure") {
            this.setState({ loginLoading: false });
            // alert('Please check your login details.');
            this.openNotification("error", result.data);
            //console.log(result);
          } else {
            this.setState({ loginLoading: false });
            this.openNotification("success", result.message);
            let {
              firstName,
              lastName,
              email,
              phoneNo,
              taccountant,
              managecat,
              crm,
              hrm,
              stock,
              invoice,
              openaccount,
              tableview,
              kitchen,
              bar,
              kitchenpermission,
              barpermission,
              tippermission,
              tdDiscountPermission
            } = result.data.info;
            let user = {
              firstName,
              lastName,
              email,
              phoneNo
            };

            let roles = {
              taccountant,
              managecat,
              crm,
              hrm,
              stock,
              invoice,
              openaccount,
              tableview,
              kitchen,
              bar,
              kitchenpermission,
              barpermission,
              tippermission,
              tdDiscountPermission
            };
            const { logo } = result.data.info.branchId;
            var empId = result.data.info._id;
            var brId = result.data.info.branchId._id;
            var isAutoPayAllowed = result.data.info.branchId.isAutoPayAllowed ? result.data.info.branchId.isAutoPayAllowed : false;
            var taxes = JSON.stringify(result.data.info.branchId.taxes) || [];
            var isQrAllow = result.data.info.branchId.isQrAllow
              ? result.data.info.branchId.isQrAllow
              : false;
            let driveoutCharge = result.data.info.branchId.driveoutCharge;
            let taxNo = result.data.info.branchId.taxNo;
            user = JSON.stringify(user);
            //("YOU GOT USER EE", user);
            localStorage.setItem("user", user);
            localStorage.setItem("eid", empId);
            localStorage.setItem("bid", brId);
            localStorage.setItem("isAutoPayAllowed", isAutoPayAllowed ? isAutoPayAllowed : false);
            localStorage.setItem("slug", result.data.info.branchId.slug);
            localStorage.setItem("address", result.data.info.branchId.address);
            localStorage.setItem("taccountant", roles.taccountant);
            localStorage.setItem("managecat", roles.managecat);
            localStorage.setItem("crm", roles.crm);
            localStorage.setItem("hrm", roles.hrm);
            localStorage.setItem("stock", roles.stock);
            localStorage.setItem("invoice", roles.invoice);
            localStorage.setItem("driveoutCharge", driveoutCharge);
            localStorage.setItem("logo", logo);
            localStorage.setItem("profileImage", result.data.info.profileImage);
            localStorage.setItem("token", result.data.token);
            setAuthorizationToken(result.data.token);
            localStorage.setItem("website", result.data.info.branchId.website);
            localStorage.setItem("empType", result.data.info.empType);
            localStorage.setItem("openaccount", roles.openaccount);
            localStorage.setItem("tableview", roles.tableview);
            localStorage.setItem("kitchen", roles.kitchen);
            localStorage.setItem("bar", roles.bar);
            localStorage.setItem("kitchenpermission", roles.kitchenpermission);
            localStorage.setItem("tdDiscountPermission", roles.tdDiscountPermission);
            localStorage.setItem("barpermission", roles.barpermission);
            localStorage.setItem("tippermission", roles.tippermission);
            localStorage.setItem("taxes", taxes);
            localStorage.setItem("taxNo", taxNo);
            localStorage.setItem("isQrAllow", isQrAllow);
            localStorage.setItem(
              "currentOrders",
              result.data.info.currentOrders
            );
            localStorage.setItem(
              "tflamount",
              result.data.info.branchId && result.data.info.branchId.tfl
                ? result.data.info.branchId.tfl.amount
                : 0
            );
            this.props.history.push("/dashboard");
          }
        },
        (error) => {
          this.openNotification(
            "error",
            "Server is currently under maintenance, please try again after some time!"
          );
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, first_name, last_name } = this.state;
    let data = {
      email: email,
      password: password,
      firstName: first_name,
      lastName: last_name
    };
    data = JSON.stringify(data);
    //console.log(data);
    fetch(`${appConfig.endpoint}/register`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", "Please enter all the details.");
            //console.log(result);
          } else {
            alert("Please Login to continue.");
            this.setState({
              isLoaded: true
            });
          }
          // this.props.history.push('/dashboard');
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    if (localStorage.token) this.props.history.push("/dashboard");
  }

  showModal = () => {
    this.setState({
      visible: true,
      email: "",
      password: ""
    });
  };

  handleOk = (e) => {
    //console.log(e);
    this.setState({
      visible: false,
      currentScreen: "email"
    });
  };

  handleCancel = (e) => {
    //console.log(e);
    this.setState({
      visible: false,
      currentScreen: "email"
    });
  };

  getOtp = (e) => {
    e.preventDefault();
    const hdr = localStorage.token;
    const { email } = this.state;
    let data = JSON.stringify({ email });
    fetch(`${appConfig.endpoint}/forgotPassword`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            //console.log(result);
            this.openNotification("error", result.message);
          } else {
            this.setState({ currentScreen: "otp" });
            this.openNotification("success", result.message);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  recoverPassword = (e) => {
    e.preventDefault();
    const hdr = localStorage.token;
    const { email, password, code } = this.state;
    let data = JSON.stringify({ email, password, code });
    //console.log(data);
    fetch(`${appConfig.endpoint}/recoverPassword`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            //console.log(result);
            // this.setState({
            //   visible: false,
            //   code: '',
            //   password: '',
            //   email: ''
            // });
            this.openNotification("error", "Please check your OTP");
          } else {
            this.setState({
              visible: false,
              code: "",
              password: "",
              email: ""
            });
            this.openNotification("success", result.message);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  render() {
    const { apiHit, currentScreen, visible, email, password } = this.state;
    return (
      <div className="landing-page-wrapper">
        <div id="message" />
        <div className="hide-when-phone">
          <div className="landing-header top-menu">
            <div>
              <button style={{ display: "none" }}>
                Helpline number : **********
              </button>
            </div>
            <nav className="landing-nav">
              <li>
                <button>Tours</button>
              </li>
              <li>
                <button>About</button>
              </li>
              <li>
                <button>Contact</button>
              </li>
            </nav>
          </div>
        </div>
        <div className="landing-header main-menu">
          <img alt="Main Logo" src={companyLogo} className="company-landing" />
          <img
            alt="Secondary Logo"
            src={companyLogo1}
            className="company-landing-small"
          />
          <nav className="landing-nav">
            <button
              type="submit"
              // loading={this.state.loginLoading}
              onClick={() => {
                this.navigate("/dashboard");
              }}
              className="display-login"
              id="login-submit-landing-alternate"
            >
              Log-in
            </button>
            <div className="login-page-hide">
              <form onSubmit={this.handleSubmitLogin} className="landing-form">
                <li>
                  <input
                    onChange={this.handleChange}
                    name="email"
                    type="email"
                    id="userName"
                    className="landing-input"
                    placeholder="Email"
                    value={visible ? "" : email}
                    required
                  />
                </li>
                <li>
                  <input
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                    id="passWord"
                    className="landing-input"
                    placeholder="Password"
                    value={password}
                    required
                  />
                </li>
                <li>
                  <button
                    // loading={this.state.loginLoading}
                    type="submit"
                    id="login-submit-landing"
                    disabled={apiHit ? true : false}
                  >
                    {this.state.loginLoading ? (
                      <Spin size="small"></Spin>
                    ) : (
                      "Login-in"
                    )}

                    {/* Log-in */}
                  </button>
                </li>
                <li>
                  <a style={{ color: "white" }} onClick={this.showModal}>
                    Forgot Password
                  </a>
                </li>
              </form>
            </div>
          </nav>
        </div>
        <section className="hero">
          <div className="background-image">
            <img
              src={background}
              className="background-image hide-when-small"
            />
            <img
              src={backgroundPhone}
              className="background-image show-when-small"
            />
          </div>
          <div className="hero-flex">
            <div className="hide-small">
              <div className="landing-content-area hero-item">
                <div className="hero-inner-flex">
                  <h1 id="animation-landing">your billing partner</h1>
                  <p id="lorem">
                    An integrated software system which will boost the
                    productivity of your restaurant, cafe or pubs. Eliminate
                    paperwork upto 80% and access data from anywhere in the
                    world with cloud technology. Manage staff, stock, customers
                    and accounting; market your products digitally and keep the
                    customers happy.
                  </p>
                </div>
              </div>
            </div>
            <Modal
              title="Forgot Password"
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer=""
              width="400px"
            >
              {currentScreen === "email" ? (
                <Form onSubmit={this.getOtp}>
                  <FormItem label="Email">
                    <Input
                      onChange={this.handleChange}
                      name="email"
                      type="email"
                      required
                    />
                    <Button type="primary" htmlType="submit">
                      Send
                    </Button>
                  </FormItem>
                </Form>
              ) : (
                <Fragment>
                  <Form onSubmit={this.recoverPassword}>
                    <FormItem label="Code">
                      <Input
                        onChange={this.handleChange}
                        type="number"
                        name="code"
                        required
                        autofocus
                      />
                    </FormItem>
                    <FormItem label="Set New Password">
                      <Input
                        onChange={this.handleChange}
                        type="password"
                        name="password"
                        required
                      />
                    </FormItem>
                    <FormItem>
                      <Button htmlType="submit" type="primary">
                        Change Password
                      </Button>
                    </FormItem>
                  </Form>
                </Fragment>
              )}
            </Modal>
            <div className="hero-item">
              {/* <form
                onSubmit={this.handleSubmit}
                className="hero-inner-flex-signup"
              >
                <li>
                  <center>
                    <h2>Your Billing Partner</h2>
                    <h5>Get Started</h5>
                  </center>
                </li>
                <li>
                  <input
                    onChange={this.handleChange}
                    name="first_name"
                    type="text"
                    className="landing-input"
                    placeholder="First Name"
                  />
                </li>
                <li>
                  <input
                    onChange={this.handleChange}
                    name="last_name"
                    type="text"
                    className="landing-input"
                    placeholder="Last Name"
                  />
                </li>
                <li>
                  <input
                    onChange={this.handleChange}
                    name="email"
                    type="text"
                    className="landing-input"
                    placeholder="E-mail"
                  />
                </li>
                <li>
                  <input
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                    className="landing-input"
                    placeholder="Password"
                  />
                </li>
                <li>
                  <center>
                    <p className="description-text">
                      Get access to a single portal that accounts for all the
                      chores.
                    </p>
                  </center>
                </li>
                <li>
                  <button type="submit" id="signup-submit-landing">
                    Sign-up
                  </button>
                </li>
              </form> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };

  // loginUser(){
  // 			const { dispatch } = this.props;
  // 			var username= document.getElementByID("userName").value;
  // 			var password= document.getElementByID("passWord").value;
  // 	dispatch(authenticate(username, password));
  // }
}
const mapStateToProps = (state) => {
  return {};
};
const LandingPage = connect(mapStateToProps)(LandingPageComponent);
export default LandingPage;
